import Typography from "@mui/material/Typography";

import Modal from "./Modal";

interface DeleteModalProps {
  open: boolean;
  loading?: boolean;
  onCancel: () => void;
  onConfirm: () => void;
  item: string;
}

export default function DeleteModal({
  open,
  onCancel,
  onConfirm,
  item,
  loading
}: DeleteModalProps) {
  return (
    <Modal
      loading={loading}
      open={open}
      title={`Delete ${item}`}
      onConfirm={onConfirm}
      onCancel={onCancel}
    >
      <Typography variant="body1">
        Do you want to delete this {item}?
      </Typography>
    </Modal>
  );
}

DeleteModal.defaultProps = {
  loading: false
};
