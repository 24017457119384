import Typography from "@mui/material/Typography";

import Modal from "./Modal";

interface CancelModalProps {
  open: boolean;
  onCancel: () => void;
  onConfirm: () => void;
}

export default function CancelModal({
  open,
  onCancel,
  onConfirm
}: CancelModalProps) {
  return (
    <Modal
      open={open}
      title="Confirm Cancel"
      onConfirm={onConfirm}
      onCancel={onCancel}
    >
      <Typography variant="body1">Do you want to cancel and quit?</Typography>
    </Modal>
  );
}
