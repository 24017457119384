export const config = {
  apiBaseUrl: process.env.REACT_APP_BASE_URL,
  apiKey:
    process.env.REACT_APP_API_KEY || "qBdyWqo3SJ5yT0WNDiUiP3ozvQVUPx8I3nDqpEPl"
};

export const awsConfig = {
  Auth: {
    userPoolId: process.env.REACT_APP_POOL_USER_ID,
    region: process.env.REACT_APP_POOL_REGION,
    userPoolWebClientId: process.env.REACT_APP_POOL_CLIENT_ID
  }
};

export const TOKEN_ACCESS = "access.token";
export const TOKEN_REFRESH = "refresh.token";

export const DEBOUNCE_TIME = 400;

export const APP_ID = "james_tr_sg_gmail_com_52475d_47d4f5";
export const APP_KEY =
  "ed87b43d111a8c3b8b08434ac8e453f4d0965ce80bcb436ad2036eb00fa24a8e";
