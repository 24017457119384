import MuiBreadcrumbs from "@mui/material/Breadcrumbs";
import Typography from "@mui/material/Typography";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleRight } from "@fortawesome/free-solid-svg-icons";
import { useLocation } from "react-router-dom";
import { useSelector } from "react-redux";

import { RootState } from "store";

export default function Breadcrumbs() {
  const location = useLocation();
  let path = location.pathname;
  path = path.replace(/\/$/, "");
  const splitPath = path.split("/");

  const { params } = useSelector((state: RootState) => state.routerParams);

  const filteredSplitPath = splitPath.filter(
    (item) => item && item !== params && item !== "app"
  );

  return (
    <MuiBreadcrumbs separator={<FontAwesomeIcon icon={faAngleRight} />}>
      {filteredSplitPath.map((breadcrumb, index) => {
        const pageName = breadcrumb.replace(/[-]+/g, " ");

        return (
          <Typography
            key={breadcrumb}
            variant="h6"
            color={index === filteredSplitPath.length - 1 ? "primary" : ""}
            sx={{ textTransform: "capitalize" }}
          >
            {pageName}
          </Typography>
        );
      })}
    </MuiBreadcrumbs>
  );
}
