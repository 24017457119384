import { red } from "@mui/material/colors";

export const labelStyles = {
  fontSize: "0.875rem",
  fontWeight: 500,
  color: "#333333",
  marginBottom: 0.5
};

export const asteriskStyles = { color: red[500] };
