import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";

import { authorizedRequest } from "services/request";
import { IAdmin } from "types/admin.model";

interface InitialStateType {
  loading: boolean;
  adminList: {
    items: IAdmin[];
    totalPage: number;
  };
  adminDetail: IAdmin;
}

const initialState: InitialStateType = {
  loading: false,
  adminList: {
    items: [],
    totalPage: 1
  },
  adminDetail: {
    id: "",
    userName: "",
    fullName: "",
    email: "",
    phoneNumber: "",
    status: "",
    active: false,
    emailVerified: false,
    createdAt: "",
    updatedAt: ""
  }
};

export const getAdminList = createAsyncThunk(
  "admins/getAdminList",
  async (state: { page: number }, { rejectWithValue }) => {
    const { page } = state;
    let params = {};
    params = { limit: 10, page };
    if (page === -1) {
      params = { all: true };
    }

    try {
      const response = (await authorizedRequest.get(
        "users/list-by-group/Admin",
        { params }
      )) as any;
      return response;
    } catch (e: any) {
      return rejectWithValue(e.message);
    }
  }
);

export const getAdminDetail = createAsyncThunk(
  "admins/getAdminDetail",
  async (state: { username: string }, { rejectWithValue }) => {
    const { username } = state;
    try {
      const response = await authorizedRequest.get(`users/${username}`);
      return response;
    } catch (e: any) {
      return rejectWithValue(e.message);
    }
  }
);

const adminsSlice = createSlice({
  name: "admins",
  initialState,
  reducers: {},
  extraReducers: {
    // admin list
    [getAdminList.pending.toString()]: (state) => {
      state.loading = true;
    },
    [getAdminList.fulfilled.toString()]: (
      state,
      action: PayloadAction<{
        items: IAdmin[];
        totalPage: number;
      }>
    ) => {
      state.loading = false;
      state.adminList = { ...action.payload };
    },
    [getAdminList.rejected.toString()]: (state) => {
      state.loading = false;
    },

    // admin detail
    [getAdminDetail.pending.toString()]: (state) => {
      state.loading = true;
    },
    [getAdminDetail.fulfilled.toString()]: (
      state,
      action: PayloadAction<IAdmin>
    ) => {
      state.loading = false;
      state.adminDetail = action.payload;
    },
    [getAdminDetail.rejected.toString()]: (state) => {
      state.loading = false;
    }
  }
});

export default adminsSlice.reducer;
