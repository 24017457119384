import axios, { Canceler, AxiosResponse } from "axios";
import { Auth } from "aws-amplify";

import { config, APP_ID, APP_KEY } from "constants/config";
import { paramsSerializer, showMessage } from "helpers/lib";

let cancel: Canceler;

const authorizedRequest = axios.create({
  baseURL: config.apiBaseUrl,
  headers: {
    "Content-Type": "application/json",
    "x-api-key": config.apiKey
  },
  cancelToken: new axios.CancelToken((c) => {
    if (cancel) cancel();
    cancel = c;
  })
});

authorizedRequest.interceptors.request.use(
  (axiosConfig: any) =>
    Auth.currentSession()
      .then((session: any) => {
        axiosConfig.headers.common.Authorization = `Bearer ${session.idToken.jwtToken}`;
        axiosConfig.paramsSerializer = (params: any) =>
          paramsSerializer(params);
        return Promise.resolve(axiosConfig);
      })
      .catch(() =>
        // No logged-in user: don't set auth header
        Promise.resolve(axiosConfig)
      ),
  (error) => Promise.reject(error)
);

authorizedRequest.interceptors.response.use(
  (response: AxiosResponse<any>) => response.data.data,
  async (error) => {
    if (error.toJSON() && error.toJSON().message === "Network Error") {
      showMessage("error", "Unauthorized");
      setTimeout(() => (window.location.href = "/auth/login"), 1000);
    }
    if (error.response && error.response.status === 400) {
      showMessage("error", error.response.data.message);
    }
    return Promise.reject(error);
  }
);

const unauthorizedRequest = axios.create({
  baseURL: config.apiBaseUrl,
  headers: {
    "Content-Type": "application/json"
  }
});

const unauthorizedRequestWithOriginal = axios.create({
  baseURL: config.apiBaseUrl,
  headers: {
    "Content-Type": "application/json",
    "x-api-key": config.apiKey,
    original: "cms"
  }
});

unauthorizedRequestWithOriginal.interceptors.response.use(
  (response: AxiosResponse<any>) => response.data.data,
  async (error) => {
    if (error.toJSON() && error.toJSON().message === "Network Error") {
      showMessage("error", "Unauthorized");
      setTimeout(() => (window.location.href = "/auth/login"), 1000);
    }
    if (error.response && error.response.status === 400) {
      showMessage("error", error.response.data.message);
    }
    return Promise.reject(error);
  }
);

const mathpixRequest = axios.create({
  baseURL: "https://api.mathpix.com/v3/text",
  headers: {
    app_id: APP_ID,
    app_key: APP_KEY,
    "Content-Type": "application/json"
  }
});

export {
  authorizedRequest,
  unauthorizedRequest,
  unauthorizedRequestWithOriginal,
  mathpixRequest
};
