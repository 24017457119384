import { StyledChip } from "./StatusBadge.styles";

interface StatusBadgeProps {
  label: string;
  color?: string;
}

export default function StatusBadge({ label, color }: StatusBadgeProps) {
  return <StyledChip color={color} label={label} />;
}

StatusBadge.defaultProps = {
  color: ""
};
