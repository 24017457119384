import { useState } from "react";
import Typography from "@mui/material/Typography";
import Popover, { PopoverProps } from "@mui/material/Popover";
import MenuList from "@mui/material/MenuList";
import MenuItem from "@mui/material/MenuItem";
import { Link } from "react-router-dom";
import { Auth } from "aws-amplify";

import { PATH_ADMIN_PROFILE, PATH_AUTH_BASE } from "router/router.paths";
import { showMessage } from "helpers/lib";
import { Modal } from "../../Modals";
import { LoadingOverlay } from "../../LoadingOverlay";
import styles from "../Header.module.css";
import { dropdownStyles, dropdownItemStyles } from "./AdminDropdown.styles";

const { link } = styles;

export default function AdminDropdown({
  open,
  anchorEl,
  onClose,
  ...props
}: PopoverProps) {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const handleClickLogout = () => {
    setIsOpen(true);
  };
  const handleClickCancelLogout = () => {
    setIsOpen(false);
  };
  const handleClickConfirmLogout = async () => {
    setIsLoading(true);
    handleClickCancelLogout();
    try {
      await Auth.signOut();
      localStorage.clear();
      showMessage("success", "Logout successfully");
      window.location.href = PATH_AUTH_BASE;
    } catch (e: any) {
      showMessage("error", e.message);
    }
    setTimeout(() => setIsLoading(false), 300);
  };

  return (
    <>
      <Popover
        open={open}
        anchorEl={anchorEl}
        onClose={onClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right"
        }}
        PaperProps={{
          sx: dropdownStyles
        }}
        {...props}
      >
        <MenuList sx={dropdownItemStyles}>
          <Link className={link} to={PATH_ADMIN_PROFILE}>
            <MenuItem>Profile</MenuItem>
          </Link>
          <MenuItem onClick={handleClickLogout}>Logout</MenuItem>
        </MenuList>
      </Popover>
      <Modal
        open={isOpen}
        loading={isLoading}
        title="Confirm Logout"
        onConfirm={handleClickConfirmLogout}
        onCancel={handleClickCancelLogout}
      >
        <Typography variant="body1">
          Are you sure you want to logout?
        </Typography>
      </Modal>
      <LoadingOverlay open={isLoading} />
    </>
  );
}
