import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";
import { v4 as uuidv4 } from "uuid";

import { authorizedRequest } from "services/request";
import {
  IQuestion,
  IAnswer,
  IMatchAnswer,
  IQuestionChildren
} from "types/refactor-question.model";
import { IProperty } from "types/property.model";

interface InitialStateType {
  loading: boolean;
  questionList: {
    items: IQuestion[];
    totalPage: number;
  };
  questionDetail: IQuestion;
  mathAnswers: IAnswer[];
  shortAnswers: IAnswer[];
  multipleChoiceAnswers: IAnswer[];
  matrixOptions: IAnswer[];
  matrixAnswers: IMatchAnswer[];
  matrixRowList: IAnswer[];
  matrixColumnList: IAnswer[];
  allProperties: {
    all: IProperty[];
    topic: IProperty[];
    paper: IProperty[];
    level: IProperty[];
    year: IProperty[];
    subject: IProperty[];
    exam: IProperty[];
  };
  questionProperties: {
    topic: string[];
    paper: string[];
    level: string[];
    year: string[];
    subject: string[];
    exam: string[];
  };
  children: IQuestionChildren[];
}

const initialState: InitialStateType = {
  loading: false,
  questionList: {
    items: [],
    totalPage: 1
  },
  questionDetail: {
    answers: [],
    content: "",
    createdAt: "",
    descriptions: "",
    id: "",
    mark: 0,
    properties: [],
    status: "",
    time: 0,
    title: "",
    type: "",
    hint: "",
    children: [],
    pictureUrl: ""
  },
  mathAnswers: [
    {
      id: uuidv4(),
      key: "result",
      value: "",
      isCorrect: true
    }
  ],
  shortAnswers: [
    {
      id: uuidv4(),
      key: "A",
      value: "",
      isCorrect: true
    }
  ],
  multipleChoiceAnswers: [
    {
      id: uuidv4(),
      key: "A",
      value: "Answer..",
      explaination: "Explaination..",
      isCorrect: true
    }
  ],
  matrixOptions: [],
  matrixRowList: [],
  matrixColumnList: [],
  matrixAnswers: [],
  allProperties: {
    all: [],
    topic: [],
    paper: [],
    level: [],
    year: [],
    subject: [],
    exam: []
  },
  questionProperties: {
    topic: [],
    paper: [],
    level: [],
    year: [],
    subject: [],
    exam: []
  },
  children: []
};

export const getQuestionList = createAsyncThunk(
  "questions/getQuestionList",
  async (state: { page: number }, { rejectWithValue }) => {
    const { page } = state;
    let params = {};
    params = { limit: 10, page };
    if (page === -1) {
      params = { all: true };
    }
    try {
      const response = (await authorizedRequest.get("questions/", {
        params
      })) as any;
      return response;
    } catch (e: any) {
      return rejectWithValue(e.message);
    }
  }
);

export const getAllProperties = createAsyncThunk(
  "properties/getAllProperties",
  async (state, { rejectWithValue }) => {
    try {
      const response = (await authorizedRequest.get(
        `/properties/?all=true`
      )) as any;
      return response.items;
    } catch (e: any) {
      return rejectWithValue(e.message);
    }
  }
);

export const getQuestionDetail = createAsyncThunk(
  "questions/getQuestionDetail",
  async (state: { id: string }, { rejectWithValue }) => {
    const { id } = state;
    try {
      const response = (await authorizedRequest.get(`questions/${id}`)) as any;
      return response;
    } catch (e: any) {
      return rejectWithValue(e.message);
    }
  }
);

export const getAllPropertiesThenQuestionDetail =
  (id: string) => async (dispatch: (arg0: any) => any) => {
    await dispatch(getQuestionDetail({ id })).unwrap();
    await dispatch(getAllProperties());
  };

const questionsSlice = createSlice({
  name: "questions",
  initialState,
  reducers: {
    // math answers
    onChangeMathAnswer: (state, action) => {
      state.mathAnswers = action.payload;
    },
    // short answers
    onAddShortAnswer: (state, action) => {
      state.shortAnswers = [...state.shortAnswers, action.payload];
    },
    onRemoveShortAnswer: (state, action) => {
      state.shortAnswers = state.shortAnswers.filter(
        (answer) => answer.id !== action.payload.id
      );
    },
    onChangeShortAnswer: (state, action) => {
      state.shortAnswers = state.shortAnswers.map((answer) => {
        if (answer.id === action.payload.id) {
          return {
            ...answer,
            value: action.payload.value
          };
        }
        return answer;
      });
    },
    // multiple choice answers
    onAddMultipleChoiceAnswer: (state, action) => {
      state.multipleChoiceAnswers = [
        ...state.multipleChoiceAnswers,
        action.payload
      ];
    },
    onRemoveMultipleChoiceAnswer: (state, action) => {
      state.multipleChoiceAnswers = state.multipleChoiceAnswers.filter(
        (answer) => answer.id !== action.payload.id
      );
    },
    onChangeMultipleChoiceAnswer: (state, action) => {
      state.multipleChoiceAnswers = state.multipleChoiceAnswers.map(
        (answer) => {
          if (answer.id === action.payload.id) {
            return {
              ...answer,
              value: action.payload.value
            };
          }
          return answer;
        }
      );
    },
    onChangeCorrectAnswer: (state, action) => {
      state.multipleChoiceAnswers = state.multipleChoiceAnswers.map(
        (answer) => {
          if (answer.id === action.payload.id) {
            return {
              ...answer,
              isCorrect: action.payload.isCorrect
            };
          }
          return answer;
        }
      );
    },
    onChangeExplainationAnswer: (state, action) => {
      state.multipleChoiceAnswers = state.multipleChoiceAnswers.map(
        (answer) => {
          if (answer.id === action.payload.id) {
            return {
              ...answer,
              explaination: action.payload.explaination,
            };
          }
          return answer;
        }
      );
    },
    onChangeImageAnswer: (state, action) => {
      state.multipleChoiceAnswers = state.multipleChoiceAnswers.map(
        (answer) => {
          if (answer.id === action.payload.id) {
            return {
              ...answer,
              imageUrl: action.payload.imageUrl
            };
          }
          return answer;
        }
      );
    },
    // matrix choice answers
    onAddMatrixRow: (state, action) => {
      state.matrixRowList = [...state.matrixRowList, action.payload];
      state.matrixOptions = [...state.matrixOptions, action.payload];
    },
    onRemoveMatrixRow: (state, action) => {
      state.matrixRowList = state.matrixRowList.filter(
        (opt) => opt.id !== action.payload.id
      );
      state.matrixOptions = state.matrixOptions.filter(
        (opt) => opt.id !== action.payload.id
      );
    },
    onAddMatrixColumn: (state, action) => {
      state.matrixColumnList = [...state.matrixColumnList, action.payload];
      state.matrixOptions = [...state.matrixOptions, action.payload];
    },
    onRemoveMatrixColumn: (state, action) => {
      state.matrixColumnList = state.matrixColumnList.filter(
        (opt) => opt.id !== action.payload.id
      );
      state.matrixOptions = state.matrixOptions.filter(
        (opt) => opt.id !== action.payload.id
      );
    },
    onChangeMatrixRow: (state, action) => {
      state.matrixRowList = state.matrixRowList.map((opt) => {
        if (opt.id === action.payload.id) {
          return {
            ...opt,
            value: action.payload.value
          };
        }
        return opt;
      });
      state.matrixOptions = state.matrixOptions.map((opt) => {
        if (opt.id === action.payload.id) {
          return {
            ...opt,
            value: action.payload.value
          };
        }
        return opt;
      });
    },
    onChangeMatrixColumn: (state, action) => {
      state.matrixColumnList = state.matrixColumnList.map((opt) => {
        if (opt.id === action.payload.id) {
          return {
            ...opt,
            value: action.payload.value
          };
        }
        return opt;
      });
      state.matrixOptions = state.matrixOptions.map((opt) => {
        if (opt.id === action.payload.id) {
          return {
            ...opt,
            value: action.payload.value
          };
        }
        return opt;
      });
    },
    onAddMatrixOption: (state, action) => {
      state.matrixOptions = [...state.matrixOptions, action.payload];
    },
    onRemoveMatrixOption: (state, action) => {
      state.matrixOptions = state.matrixOptions.filter(
        (opt) => opt.id !== action.payload.id
      );
    },
    onChangeMatrixOption: (state, action) => {
      state.matrixOptions = state.matrixOptions.map((opt) => {
        if (opt.id === action.payload.id) {
          return {
            ...opt,
            value: action.payload.value
          };
        }
        return opt;
      });
    },
    onChangeMatrixAnswer: (state, action) => {
      const currentRow = state.matrixRowList.find(
        (row) => row.key === action.payload.rowKey
      );
      const currentCol = state.matrixColumnList.find(
        (col) => col.key === action.payload.colKey
      );
      if (!currentCol || !currentRow) return;
      if (action.payload.isChecked) {
        const matchAnswers = [];

        // matchAnswers.push({
        //   value1: currentRow.value,
        //   value2: currentCol.value
        // });
        const matrixAnswers = [...state.matrixAnswers, ...matchAnswers];
        state.matrixAnswers = matrixAnswers.filter(
          (ans, index, array) =>
            index ===
            array.findIndex(
              (t) => t.value1 === ans.value1 && t.value2 === ans.value2
            )
        );
      } else {
        const answerIndex = state.matrixAnswers.findIndex(
          (ans) =>
            ans.value1 === currentRow.value && ans.value2 === currentCol.value
        );
        const clonedMatrixAnswers = [...state.matrixAnswers];
        clonedMatrixAnswers.splice(answerIndex, 1);
        state.matrixAnswers = [...clonedMatrixAnswers].filter(
          (ans, index, array) =>
            index ===
            array.findIndex(
              (t) => t.value1 === ans.value1 && t.value2 === ans.value2
            )
        );
      }
    },
    clearAnswers: (state) => {
      state.mathAnswers = [
        {
          id: uuidv4(),
          key: "result",
          value: "",
          isCorrect: true
        }
      ];
      state.shortAnswers = [
        {
          id: uuidv4(),
          key: "A",
          value: "",
          isCorrect: true
        }
      ];
      state.matrixColumnList = [];
      state.matrixRowList = [];
      state.matrixOptions = [];
      state.matrixAnswers = [];
      state.multipleChoiceAnswers = [
        {
          id: uuidv4(),
          key: "A",
          value: "",
          isCorrect: true
        }
      ];
      state.questionProperties = {
        topic: [],
        paper: [],
        level: [],
        year: [],
        subject: [],
        exam: []
      };
      state.questionDetail = {
        answers: [],
        content: "",
        createdAt: "",
        descriptions: "",
        id: "",
        mark: 0,
        properties: [],
        status: "",
        time: 0,
        title: "",
        type: "",
        hint: "",
        children: []
      };
      state = initialState;
    }
  },
  extraReducers: {
    // question list
    [getQuestionList.pending.toString()]: (state) => {
      state.loading = true;
    },
    [getQuestionList.fulfilled.toString()]: (
      state,
      action: PayloadAction<{
        items: IQuestion[];
        totalPage: number;
      }>
    ) => {
      state.loading = false;
      state.questionList = { ...action.payload };
    },
    [getQuestionList.rejected.toString()]: (state) => {
      state.loading = false;
    },

    // get all properties
    [getAllProperties.pending.toString()]: (state) => {},
    [getAllProperties.fulfilled.toString()]: (
      state,
      action: PayloadAction<IProperty[]>
    ) => {
      state.allProperties = {
        all: [...action.payload],
        topic: [...action.payload].filter((item) => item.type === "Topic"),
        paper: [...action.payload].filter((item) => item.type === "Paper"),
        level: [...action.payload].filter((item) => item.type === "Level"),
        year: [...action.payload].filter((item) => item.type === "Year"),
        subject: [...action.payload].filter((item) => item.type === "Subject"),
        exam: [...action.payload].filter((item) => item.type === "Exam")
      };
    },
    [getAllProperties.rejected.toString()]: (state) => {},

    // question detail
    [getQuestionDetail.pending.toString()]: (state) => {
      state.loading = true;
    },
    [getQuestionDetail.fulfilled.toString()]: (
      state,
      action: PayloadAction<IQuestion>
    ) => {
      state.loading = false;
      state.questionDetail = action.payload;
      state.questionProperties = {
        topic: state.questionDetail.properties
          ? [...state.questionDetail.properties]
              .filter((item) => item.type === "Topic")
              .map((j) => j.id)
          : [],
        paper: state.questionDetail.properties
          ? [...state.questionDetail.properties]
              .filter((item) => item.type === "Paper")
              .map((j) => j.id)
          : [],
        level: state.questionDetail.properties
          ? [...state.questionDetail.properties]
              .filter((item) => item.type === "Level")
              .map((j) => j.id)
          : [],
        year: state.questionDetail.properties
          ? [...state.questionDetail.properties]
              .filter((item) => item.type === "Year")
              .map((j) => j.id)
          : [],
        subject: state.questionDetail.properties
          ? [...state.questionDetail.properties]
              .filter((item) => item.type === "Subject")
              .map((j) => j.id)
          : [],
        exam: state.questionDetail.properties
          ? [...state.questionDetail.properties]
              .filter((item) => item.type === "Exam")
              .map((j) => j.id)
          : []
      };

      if (state.questionDetail.type === "Math") {
        state.mathAnswers = state.questionDetail.answers;
      }

      if (state.questionDetail.type === "Math-Type-2") {
        if (state.questionDetail.children) {
          state.children = state.questionDetail.children;
        }
      }

      if (state.questionDetail.type === "Short-Answer") {
        const shortAnswerList = state.questionDetail.answers.map((answer) => ({
          ...answer,
          id: uuidv4()
        }));
        state.shortAnswers = shortAnswerList;
      }

      if (state.questionDetail.type === "Multi-Choice") {
        const multipleChoiceAnswers = state.questionDetail.answers.map(
          (answer) => ({
            ...answer,
            explaination: `${answer.explaination}<p></p>`,
            value: `${answer.value}<p></p>`,
            id: uuidv4()
          })
        );
        state.multipleChoiceAnswers = multipleChoiceAnswers;
      }

      if (
        state.questionDetail.type === "Grid-Multi-Choice" &&
        state.questionDetail.matchAnswers
      ) {
        const answerList = state.questionDetail.answers.map((answer) => ({
          ...answer,
          id: uuidv4()
        }));
        state.matrixOptions = answerList;
        state.matrixColumnList = answerList.filter(
          (ans) => ans.answerType === "Col"
        );
        state.matrixRowList = answerList.filter(
          (ans) => ans.answerType === "Row"
        );
        state.matrixAnswers = state.questionDetail.matchAnswers;
      }
    },
    [getQuestionDetail.rejected.toString()]: (state) => {
      state.loading = false;
    }
  }
});

export const {
  onChangeMathAnswer,
  onAddShortAnswer,
  onChangeShortAnswer,
  onRemoveShortAnswer,
  onAddMultipleChoiceAnswer,
  onRemoveMultipleChoiceAnswer,
  onChangeMultipleChoiceAnswer,
  onChangeCorrectAnswer,
  onChangeExplainationAnswer,
  onChangeImageAnswer,
  onChangeMatrixAnswer,
  onAddMatrixOption,
  onRemoveMatrixOption,
  onAddMatrixRow,
  onAddMatrixColumn,
  onRemoveMatrixColumn,
  onRemoveMatrixRow,
  onChangeMatrixColumn,
  onChangeMatrixRow,
  clearAnswers
} = questionsSlice.actions;

export default questionsSlice.reducer;
