import InputAdornment from "@mui/material/InputAdornment";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch } from "@fortawesome/free-solid-svg-icons";

import Input, { InputProps } from "./Input";

export default function SearchInput({ ...props }: InputProps) {
  return (
    <Input
      InputProps={{
        startAdornment: (
          <InputAdornment position="end">
            <FontAwesomeIcon icon={faSearch} />
          </InputAdornment>
        ),
        sx: { paddingLeft: "10px", "& > input": { paddingLeft: "14px" } }
      }}
      {...props}
    />
  );
}
