import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";
import { useNavigate } from "react-router-dom";

import { Button } from "../Button";
import { goBackStyles, paperStyles, buttonStyles } from "./GoBack.styles";

interface GoBackProps {
  path?: string;
}

export default function GoBack({ path }: GoBackProps) {
  const navigate = useNavigate();

  const handleClick = () => {
    if (path) {
      navigate(path);
    } else {
      navigate(-1);
    }
  };

  return (
    <Grid sx={goBackStyles}>
      <Paper sx={paperStyles}>
        <Button sx={buttonStyles} onClick={handleClick}>
          <FontAwesomeIcon icon={faArrowLeft} />
          Go back
        </Button>
      </Paper>
    </Grid>
  );
}

GoBack.defaultProps = {
  path: ""
};
