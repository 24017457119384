import { ReactNode, ChangeEvent, useState, useEffect } from "react";
import MuiTable, { TableProps as MuiTableProps } from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import CircularProgress from "@mui/material/CircularProgress";
import Pagination from "@mui/material/Pagination";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import { useNavigate, useLocation } from "react-router-dom";

const queryString = require("query-string");

interface TableProps extends MuiTableProps {
  loading?: boolean;
  head: ReactNode;
  colSpan: number;
  totalPage: number;
  data: any[];
  renderRows: Function;
  inputPage?: boolean;
}

export default function Table({
  loading,
  head,
  data,
  colSpan,
  renderRows,
  totalPage,
  inputPage
}: TableProps) {
  const [currentPage, setCurrentPage] = useState<number>(1);
  const navigate = useNavigate();
  const location = useLocation();

  const locationSearch = queryString.parse(location.search);

  useEffect(() => {
    if (locationSearch.page) {
      setCurrentPage(Number(locationSearch.page));
    }
  }, [locationSearch.page]);

  const renderTableBody = () => {
    if (loading) {
      return (
        <TableRow>
          <TableCell
            align="center"
            style={{ border: "none" }}
            colSpan={colSpan}
          >
            <CircularProgress />
          </TableCell>
        </TableRow>
      );
    }
    if (data?.length === 0 || !data) {
      return (
        <TableRow>
          <TableCell
            align="center"
            style={{ border: "none" }}
            colSpan={colSpan}
          >
            <Typography variant="body1" sx={{ color: "#333333" }}>
              No data
            </Typography>
          </TableCell>
        </TableRow>
      );
    }
    return data.map((item) => renderRows(item));
  };
  const handleChangePage = (
    event: ChangeEvent<unknown>,
    pageNumber: number
  ) => {
    setCurrentPage(pageNumber);
    navigate(`${location.pathname}?page=${pageNumber}`);
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const pageNumber = parseInt(event.target.value, 10);
    setCurrentPage(pageNumber);
    if (pageNumber >= 1 && pageNumber <= totalPage) {
      navigate(`${location.pathname}?page=${pageNumber}`);
    }
  };

  return (
    <TableContainer component={Paper}>
      <MuiTable>
        <TableHead>
          <TableRow>{head}</TableRow>
        </TableHead>
        <TableBody>{renderTableBody()}</TableBody>
      </MuiTable>
      {data.length > 0 && (
        <Grid
          container
          sx={{
            padding: 2,
            justifyContent: inputPage ? "space-between" : "flex-end"
          }}
        >
          {inputPage ? (
            <TextField
              type="number"
              label="Page"
              size="small"
              style={{ width: "100px", marginRight: "16px" }}
              onChange={handleChange}
              value={currentPage}
              inputProps={{ min: "1", max: totalPage }}
            />
          ) : null}
          <Pagination
            count={totalPage}
            page={currentPage}
            variant="outlined"
            color="primary"
            onChange={handleChangePage}
          />
        </Grid>
      )}
    </TableContainer>
  );
}

Table.defaultProps = {
  loading: false,
  inputPage: true
};
