import { Outlet } from "react-router-dom";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";

import logoImg from "images/logo.png";
import {
  StyledGridLeft,
  authLayoutStyles,
  mainStyles,
  rightGridStyles
} from "./AuthLayout.styles";

export default function AuthLayout() {
  return (
    <Grid container sx={authLayoutStyles}>
      <StyledGridLeft item lg={5} md={6}>
        <img src={logoImg} alt="logo" />
      </StyledGridLeft>
      <Grid item lg={7} md={6} sx={rightGridStyles}>
        <Box component="main" sx={mainStyles}>
          <Outlet />
        </Box>
      </Grid>
    </Grid>
  );
}
