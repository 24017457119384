import { styled } from "@mui/material/styles";
import Grid from "@mui/material/Grid";

import authBgImg from "images/auth-bg.jpg";

export const StyledGridLeft = styled(Grid)(({ theme }) => ({
  backgroundImage: `url(${authBgImg})`,
  backgroundSize: "cover",
  padding: theme.spacing(5),
  "& > img": {
    width: 150
  }
}));

export const authLayoutStyles = {
  minHeight: "100vh"
};

export const mainStyles = {
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  height: "100%"
};

export const rightGridStyles = {
  backgroundColor: "#fafafa"
};
