import { configureStore } from "@reduxjs/toolkit";

import routerParamsSlice from "./router-params.slice";
import adminsSlice from "./admin.slice";
import studentsSlice from "./student.slice";
import businessSlice from "./business.slice";
import teachmeSlice from "./teachme.slice";
import propertySlice from "./property.slice";
import questionSlice from "./question.slice";
import authSlice from "./auth.slice";

export const store = configureStore({
  reducer: {
    routerParams: routerParamsSlice,
    admins: adminsSlice,
    students: studentsSlice,
    business: businessSlice,
    teachme: teachmeSlice,
    properties: propertySlice,
    questions: questionSlice,
    auth: authSlice
  }
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
