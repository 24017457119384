import { ChangeEvent, useState } from "react";
import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";
import CircularProgress from "@mui/material/CircularProgress";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUpload } from "@fortawesome/free-solid-svg-icons";
import { grey } from "@mui/material/colors";

import styles from "./Upload.module.css";

const { name } = styles;

interface UploadProps {
  id: string;
  loading?: boolean;
  fileName?: boolean;
  direction?: "row" | "column";
  onChange: (file: File) => void;
  onClear?: () => void;
  multiple?: boolean;
}

export default function Upload({
  id,
  fileName,
  loading,
  multiple,
  onChange,
  onClear,
  direction
}: UploadProps) {
  const [key, setKey] = useState<string>("");
  const [selectedFileName, setSelectedFileName] = useState<string>("");

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    if (event.target.files) {
      setSelectedFileName(event.target.files[0].name);
      onChange(event.target.files[0]);
    }
  };
  const handleResetInput = () => {
    setKey(Math.random().toString(36));
  };

  return (
    <Stack direction={direction} sx={{ display: "flex", alignItems: "center" }}>
      <label htmlFor={id}>
        <input
          hidden
          key={key}
          multiple={multiple}
          id={id}
          type="file"
          onChange={handleChange}
        />
        <Button
          disabled={loading}
          disableElevation
          color="primary"
          variant="contained"
          component="span"
          sx={{ "& > svg": { marginRight: 1 } }}
          onClick={handleResetInput}
        >
          {loading ? (
            <CircularProgress
              size="1rem"
              sx={{ marginRight: 1, "& > svg": { color: grey[500] } }}
            />
          ) : (
            <FontAwesomeIcon icon={faUpload} />
          )}
          Upload
        </Button>
      </label>
      {fileName && selectedFileName && (
        <span className={name}>{selectedFileName}</span>
      )}
    </Stack>
  );
}

Upload.defaultProps = {
  loading: false,
  multiple: false,
  direction: "row",
  fileName: false,
  onClear: () => {}
};
