import LoadingButton, { LoadingButtonProps } from "@mui/lab/LoadingButton";
import { Link } from "react-router-dom";

interface CustomButtonProps extends LoadingButtonProps {
  path?: string;
}

export default function Button({ path, ...props }: CustomButtonProps) {
  const renderButton = () => {
    if (path) {
      return (
        <Link to={path}>
          <LoadingButton disableElevation {...props} />
        </Link>
      );
    }
    return <LoadingButton disableElevation {...props} />;
  };

  return renderButton();
}

Button.defaultProps = {
  path: ""
};
