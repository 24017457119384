import { ReactNode } from "react";
import Stack from "@mui/material/Stack";
import Dialog from "@mui/material/Dialog";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";

import { Button } from "../Button";

export interface ModalProps {
  open: boolean;
  children: ReactNode;
  onCancel: () => void;
  onConfirm: () => void;
  title?: string;
  width?: number | string;
  okText?: string;
  cancelText?: string;
  loading?: boolean;
}

export default function Modal({
  open,
  children,
  onCancel,
  onConfirm,
  title,
  width,
  okText,
  cancelText,
  loading
}: ModalProps) {
  return (
    <Dialog open={open} PaperProps={{ sx: { width, maxWidth: "none" } }}>
      <Grid
        container
        justifyContent="space-between"
        alignItems="center"
        sx={{ paddingTop: 2, paddingLeft: 2, paddingRight: 2 }}
      >
        <Typography variant="body1" sx={{ fontWeight: 500 }}>
          {title}
        </Typography>
        <IconButton size="small" onClick={onCancel}>
          <FontAwesomeIcon icon={faTimes} />
        </IconButton>
      </Grid>

      <Grid sx={{ padding: 2 }}>{children}</Grid>

      <Grid
        container
        alignItems="center"
        justifyContent="flex-end"
        sx={{ padding: 2 }}
      >
        <Stack direction="row" spacing={1}>
          <Button variant="outlined" onClick={onCancel}>
            {cancelText}
          </Button>
          <Button loading={loading} variant="contained" onClick={onConfirm}>
            {okText}
          </Button>
        </Stack>
      </Grid>
    </Dialog>
  );
}

Modal.defaultProps = {
  title: "",
  width: "30rem",
  okText: "Confirm",
  cancelText: "Cancel",
  loading: false
};
