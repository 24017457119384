import MathJax from "react-mathjax-preview";

interface MathjaxParserProps {
  mString: string;
}
const MathjaxParser = ({ mString }: MathjaxParserProps) => (
  <MathJax
    id="math-preview"
    math={mString}
    config={{
      tex2jax: {
        inlineMath: [['$','$'], ['\\(','\\)']],
        processEscapes: true
      },
      "HTML-CSS": {
        fonts: ["MathJax-TeX"],
        undefinedFamily: "Bebeboo",
        // scale: 100,
        linebreaks: {
          automatic: true,
          width: "340px"
        }
      },
      // CommonHTML: { linebreaks: { automatic: true, width: "container" } },
      // SVG: { fontCache: 'global', linebreaks: { automatic: true, width: "container" } }
    }}
  />
);
export default MathjaxParser;
