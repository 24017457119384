import { useState, useEffect, ChangeEvent } from "react";
import InputAdornment from "@mui/material/InputAdornment";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCopy } from "@fortawesome/free-solid-svg-icons";
import IconButton from "@mui/material/IconButton";
import { CopyToClipboard } from "react-copy-to-clipboard";

import Input, { InputProps } from "./Input";

export default function CopyableInput({
  value,
  onChange,
  ...props
}: InputProps) {
  const [inputValue, setInputValue] = useState<any>(value);

  useEffect(() => {
    setInputValue(value);
  }, [value]);

  const handleChange = (event: ChangeEvent<HTMLInputElement>): void => {
    setInputValue(event.target.value);
    onChange?.(event);
  };

  return (
    <Input
      InputProps={{
        endAdornment: (
          <InputAdornment position="end">
            <CopyToClipboard text={value || inputValue}>
              <IconButton>
                <FontAwesomeIcon icon={faCopy} size="xs" />
              </IconButton>
            </CopyToClipboard>
          </InputAdornment>
        )
      }}
      value={inputValue}
      onChange={handleChange}
      {...props}
    />
  );
}
