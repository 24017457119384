import Grid, { GridProps } from "@mui/material/Grid";

import avatarPlaceholder from "images/avatar-placeholder.png";
import imagePlaceholder from "images/placeholder-camera.jpg";
import { StyledImage } from "./ImagePlaceholder.styles";

interface ImagePlaceholderProps extends GridProps {
  type?: "avatar" | "image";
  image: string;
}

export default function ImagePlaceholder({
  image,
  type,
  ...props
}: ImagePlaceholderProps) {
  return (
    <Grid {...props} className="h-full">
      <StyledImage
        src={image || (type === "image" ? imagePlaceholder : avatarPlaceholder)}
        alt="business-logo"
      />
    </Grid>
  );
}

ImagePlaceholder.defaultProps = {
  type: "image"
};
