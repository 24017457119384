import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faDownload } from "@fortawesome/free-solid-svg-icons";

import { Button } from "../Button";
import { buttonStyles } from "./Download.styles";

interface DownloadProps {
  link: string;
}

export default function Download({ link }: DownloadProps) {
  return (
    <a href={link} download>
      <Button variant="contained" sx={buttonStyles}>
        <FontAwesomeIcon icon={faDownload} />
        Download
      </Button>
    </a>
  );
}
