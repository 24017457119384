import MuiSelect, {
  SelectProps,
  SelectChangeEvent
} from "@mui/material/Select";
import FormHelperText from "@mui/material/FormHelperText";
import InputLabel from "@mui/material/InputLabel";
import Box from "@mui/material/Box";
import { red } from "@mui/material/colors";

import { MenuProps, selectStyles, labelStyles } from "./Select.styles";

interface CustomSelectProps extends SelectProps {
  label?: string;
  errorMessage?: string;
  required?: boolean;
  onChange?: (event: SelectChangeEvent<any>) => void;
}

export default function Select({
  onChange,
  errorMessage,
  sx,
  label,
  required,
  ...props
}: CustomSelectProps) {
  return (
    <div>
      <InputLabel error={Boolean(errorMessage)} sx={labelStyles}>
        {label}{" "}
        {required && (
          <Box component="span" sx={{ color: red[500] }}>
            *
          </Box>
        )}
      </InputLabel>
      <MuiSelect
        error={Boolean(errorMessage)}
        MenuProps={MenuProps}
        onChange={onChange}
        size="small"
        sx={{ ...selectStyles, ...sx }}
        {...props}
      />

      {errorMessage && (
        <FormHelperText
          error={Boolean(errorMessage)}
          sx={{
            marginTop: "4px",
            marginRight: "14px",
            marginBottom: 0,
            marginLeft: "14px"
          }}
        >
          {errorMessage}
        </FormHelperText>
      )}
    </div>
  );
}

Select.defaultProps = {
  label: "",
  onChange: () => {},
  errorMessage: "",
  required: false
};
