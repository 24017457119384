import { useState, MouseEvent } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUserCircle } from "@fortawesome/free-solid-svg-icons";
import Grid from "@mui/material/Grid";
import IconButton from "@mui/material/IconButton";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";

import { Breadcrumbs } from "../Breadcrumbs";
import { StyledAppBar } from "./Header.styles";
import { AdminDropdown } from "./components";

interface HeaderProps {
  open: boolean;
}

export default function Header({ open }: HeaderProps) {
  const [anchorEl, setAnchorEl] = useState<Element | null>(null);

  const handleClickOpenAdminDropdown = (event: MouseEvent<Element>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClickCloseAdminDropdown = () => {
    setAnchorEl(null);
  };

  return (
    <StyledAppBar position="fixed" open={open}>
      <Grid
        container
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        sx={{ height: "100%" }}
      >
        <Breadcrumbs />
        <Box sx={{ display: "flex", alignItems: "center" }}>
          <Typography
            variant="body2"
            sx={{ color: "#333333", marginRight: 0.5 }}
          >
            Hi, Admin
          </Typography>

          <IconButton onClick={handleClickOpenAdminDropdown}>
            <FontAwesomeIcon icon={faUserCircle} />
          </IconButton>
          <AdminDropdown
            open={Boolean(anchorEl)}
            anchorEl={anchorEl}
            onClose={handleClickCloseAdminDropdown}
          />
        </Box>
      </Grid>
    </StyledAppBar>
  );
}
