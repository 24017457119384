import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";

interface LoadingOverlayProps {
  open: boolean;
}

export default function LoadingOverlay({ open }: LoadingOverlayProps) {
  return (
    <Backdrop
      sx={{
        color: "#ffffff",
        zIndex: 99999
      }}
      open={open}
    >
      <CircularProgress color="inherit" />
    </Backdrop>
  );
}
