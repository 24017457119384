import { ReactNode } from "react";
import { createTheme, ThemeProvider, ThemeOptions } from "@mui/material/styles";

const theme = createTheme({
  palette: {
    primary: {
      main: "#7A6FF9",
      dark: "#5749f7"
    }
  },
  typography: {
    fontFamily: "'Poppins', sans-serif"
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          borderRadius: "3.125rem",
          fontWeight: 600,
          padding: "0.5rem 1rem"
        },
        outlined: {
          borderWidth: "2px",
          borderColor: "#7A6FF9",
          "&:hover": {
            borderWidth: "2px",
            borderColor: "#5749f7"
          }
        }
      }
    }
  }
} as ThemeOptions);

interface ThemeProps {
  children: ReactNode;
}

function Theme({ children }: ThemeProps) {
  return <ThemeProvider theme={theme}>{children}</ThemeProvider>;
}

export default Theme;
