import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";

import { authorizedRequest } from "services/request";
import { IBook } from "types/teachme.model";

interface InitialStateType {
  loading: boolean;
  bookList: {
    items: IBook[];
    totalPage: number;
  };
  bookDetail: IBook;
}

const initialState: InitialStateType = {
  loading: false,
  bookList: {
    items: [],
    totalPage: 1
  },
  bookDetail: {
    id: "",
    descriptions: "",
    status: "",
    fileUrl: "",
    createdAt: "",
    titleImage: "",
    title: ""
  }
};

export const getBookList = createAsyncThunk(
  "teachMe/getBookList",
  async (state: { page: number }, { rejectWithValue }) => {
    const { page } = state;
    let params = {};
    params = { limit: 10, page };
    if (page === -1) {
      params = { all: true };
    }

    try {
      const response = (await authorizedRequest.get("books", {
        params
      })) as any;
      return response;
    } catch (e: any) {
      return rejectWithValue(e.message);
    }
  }
);

export const getBookDetail = createAsyncThunk(
  "teachMe/getBookDetail",
  async (state: { id: string }, { rejectWithValue }) => {
    const { id } = state;
    try {
      const response = (await authorizedRequest.get(`books/${id}`)) as any;
      return response;
    } catch (e: any) {
      return rejectWithValue(e.message);
    }
  }
);

const teachMeSlice = createSlice({
  name: "teachMe",
  initialState,
  reducers: {},
  extraReducers: {
    // book list
    [getBookList.pending.toString()]: (state) => {
      state.loading = true;
    },
    [getBookList.fulfilled.toString()]: (
      state,
      action: PayloadAction<{
        items: IBook[];
        totalPage: number;
      }>
    ) => {
      state.loading = false;
      state.bookList = { ...action.payload };
    },
    [getBookList.rejected.toString()]: (state) => {
      state.loading = false;
    },

    // book detail
    [getBookDetail.pending.toString()]: (state) => {
      state.loading = true;
    },
    [getBookDetail.fulfilled.toString()]: (
      state,
      action: PayloadAction<IBook>
    ) => {
      state.loading = false;
      state.bookDetail = action.payload;
    },
    [getBookDetail.rejected.toString()]: (state) => {
      state.loading = false;
    }
  }
});

export default teachMeSlice.reducer;
