import { styled } from "@mui/material/styles";
import MenuItem from "@mui/material/MenuItem";

export const StyledMenuItemOption = styled(MenuItem)(({ theme }) => ({
  borderRadius: "4px",
  color: "#333333",
  "&:hover": {
    borderRadius: "4px",
    backgroundColor: theme.palette.primary.main,
    color: "#ffffff"
  },
  "&.Mui-selected": {
    backgroundColor: "#ffffff",
    color: theme.palette.primary.main,
    fontWeight: 600
  },
  "&.Mui-selected:hover": {
    borderRadius: "4px",
    backgroundColor: theme.palette.primary.main,
    color: "#ffffff"
  }
}));

export const MenuProps: any = {
  PaperProps: {
    style: {
      maxHeight: 230,
      overflowY: "hidden",
      paddingLeft: 8,
      paddingRight: 8,
      paddingTop: 8,
      paddingBottom: 8
    }
  },
  MenuListProps: {
    sx: {
      maxHeight: "calc(210px - 16px)",
      overflowY: "auto",
      "&::-webkit-scrollbar": {
        width: "13px",
        background: "#ffffff"
      },
      "&::-webkit-scrollbar-track": {
        background: "#e5e5e5",
        borderLeft: "8px solid #ffffff"
      },
      "&::-webkit-scrollbar-thumb": {
        background: "#cbcbcb",
        borderLeft: "8px solid #ffffff",
        backgroundClip: "padding-box"
      }
    }
  }
};

export const selectStyles = {
  backgroundColor: "#ffffff",
  minWidth: 230,
  "& > div": {
    fontSize: "14px",
    paddingRight: "31px !important"
  },
  "&.Mui-disabled > div": {
    backgroundColor: "#e2e2e2"
  }
};

export const labelStyles = {
  fontSize: "0.875rem",
  fontWeight: 500,
  color: "#333333",
  marginBottom: 0.5
};
