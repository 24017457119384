// AUTHENTICATION
export const PATH_AUTH_BASE = "/auth";
export const PATH_LOGIN = "/auth/login";
export const PATH_FORGOT_PASSWORD = "/auth/forgot-password";
export const PATH_VERIFY_EMAIL = "/auth/verify-email";

// APP
export const PATH_APP_BASE = "/app";
export const PATH_DASHBOARD = "/app/dashboard";

export const PATH_ADMIN_MANAGEMENT = "/app/admin-management";
export const PATH_ADMIN_MANAGEMENT_LIST = "/app/admin-management/list";
export const PATH_ADMIN_CREATE = "/app/admin-management/create";
export const PATH_ADMIN_EDIT = "/app/admin-management/edit/:id";

export const PATH_STUDENT_MANAGEMENT = "/app/student-management";
export const PATH_STUDENT_MANAGEMENT_LIST = "/app/student-management/list";
export const PATH_STUDENT_CREATE = "/app/student-management/create";
export const PATH_STUDENT_EDIT = "/app/student-management/edit/:id";

export const PATH_BUSINESS_MANAGEMENT = "/app/business-management";
export const PATH_BUSINESS_MANAGEMENT_LIST = "/app/business-management/list";
export const PATH_BUSINESS_CREATE = "/app/business-management/create";
export const PATH_BUSINESS_CREATE_USER =
  "/app/business-management/edit/:businessId/create-user";
export const PATH_BUSINESS_EDIT_USER =
  "/app/business-management/edit/:businessId/edit-user/:userName";
export const PATH_BUSINESS_EDIT = "/app/business-management/edit/:id";
export const PATH_BUSINESS_EDIT_ADD_USER =
  "/app/business-management/edit/:id/add-user-to-business";

export const PATH_TEACHME_MANAGEMENT = "/app/teach-me-management";
export const PATH_TEACHME_MANAGEMENT_LIST = "/app/teach-me-management/list";
export const PATH_TEACHME_CREATE = "/app/teach-me-management/create";
export const PATH_TEACHME_EDIT = "/app/teach-me-management/edit/:id";

export const PATH_TOPIC_MANAGEMENT =
  "/app/property-management/topic-management";
export const PATH_TOPIC_MANAGEMENT_LIST =
  "/app/property-management/topic-management/list";

export const PATH_PAPER_MANAGEMENT =
  "/app/property-management/paper-management";
export const PATH_PAPER_MANAGEMENT_LIST =
  "/app/property-management/paper-management/list";

export const PATH_LEVEL_MANAGEMENT =
  "/app/property-management/level-management";
export const PATH_LEVEL_MANAGEMENT_LIST =
  "/app/property-management/level-management/list";

export const PATH_YEAR_MANAGEMENT = "/app/property-management/year-management";
export const PATH_YEAR_MANAGEMENT_LIST =
  "/app/property-management/year-management/list";

export const PATH_SUBJECT_MANAGEMENT =
  "/app/property-management/subject-management";
export const PATH_SUBJECT_MANAGEMENT_LIST =
  "/app/property-management/subject-management/list";

export const PATH_EXAM_TYPE_MANAGEMENT =
  "/app/property-management/exam-type-management";
export const PATH_EXAM_TYPE_MANAGEMENT_LIST =
  "/app/property-management/exam-type-management/list";

export const PATH_QUESTION_MANAGEMENT = "/app/question-management";
export const PATH_QUESTION_MANAGEMENT_LIST = "/app/question-management/list";
export const PATH_QUESTION_CREATE = "/app/question-management/create";
export const PATH_QUESTION_EDIT = "/app/question-management/edit/:id";

export const PATH_ADMIN_PROFILE = "/app/admin-profile";
