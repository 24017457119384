import { styled } from "@mui/material/styles";
import AppBar, { AppBarProps } from "@mui/material/AppBar";

interface CustomAppBarProps extends AppBarProps {
  open: boolean;
}

export const StyledAppBar = styled(AppBar, {
  shouldForwardProp: (prop) => prop !== "open"
})<CustomAppBarProps>(({ theme, open }) => ({
  backgroundColor: "#ffffff",
  borderBottom: "1px solid #cecece",
  height: 64,
  paddingRight: theme.spacing(2),
  paddingLeft: theme.spacing(2),
  ...(open
    ? {
        marginLeft: 300,
        width: `calc(100% - ${300}px)`,
        transition: theme.transitions.create(["width", "margin"], {
          easing: theme.transitions.easing.sharp,
          duration: theme.transitions.duration.enteringScreen
        })
      }
    : {
        marginLeft: 73,
        width: `calc(100% - ${73}px)`,
        transition: theme.transitions.create(["width", "margin"], {
          easing: theme.transitions.easing.sharp,
          duration: theme.transitions.duration.enteringScreen
        })
      })
}));
