import ReactPhoneInput, { parsePhoneNumber } from "react-phone-number-input";
import InputLabel from "@mui/material/InputLabel";

import { labelStyles } from "./FormControl.styles";
import "./FormControl.styles.css";

interface PhoneInputProps {
  value?: string;
  onChange?: (value: { countryCode: string; phoneNumber: string }) => void;
  label?: string;
}

export default function PhoneInput({
  label,
  value,
  onChange
}: PhoneInputProps) {
  const handleChange = (values: string) => {
    if (values) {
      const parsedPhoneNumber = parsePhoneNumber(values);
      if (parsedPhoneNumber) {
        const number = {
          countryCode: parsedPhoneNumber.countryCallingCode as string,
          phoneNumber: parsedPhoneNumber.nationalNumber as string
        };
        onChange?.(number);
      }
    }
  };

  return (
    <div>
      <InputLabel sx={labelStyles}>{label}</InputLabel>
      <ReactPhoneInput value={value} country="VN" onChange={handleChange} />
    </div>
  );
}

PhoneInput.defaultProps = {
  label: "",
  value: "",
  onChange: (value: { countryCode: string; phoneNumber: string }) => {}
};
