import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";

import { authorizedRequest } from "services/request";
import { IAdmin } from "types/admin.model";
import { IUser } from "types/user.model";

interface InitialStateType {
  loading: boolean;
  studentList: {
    items: IUser[];
    totalPage: number;
  };
  studentDetail: IAdmin;
}

const initialState: InitialStateType = {
  loading: false,
  studentList: {
    items: [],
    totalPage: 1
  },
  studentDetail: {
    id: "",
    userName: "",
    fullName: "",
    email: "",
    phoneNumber: "",
    status: "",
    active: false,
    emailVerified: false,
    createdAt: "",
    updatedAt: ""
  }
};

export const getStudentList = createAsyncThunk(
  "admins/getStudentList",
  async (state: { page: number }, { rejectWithValue }) => {
    const { page } = state;
    let params = {};
    params = { type: "Individual", limit: 10, page };
    if (page === -1) {
      params = { type: "Individual", all: true };
    }
    try {
      const response = (await authorizedRequest.get("/business/list-users", {
        params
      })) as any;
      return response;
    } catch (e: any) {
      return rejectWithValue(e.message);
    }
  }
);

export const getStudentDetail = createAsyncThunk(
  "admins/getStudentDetail",
  async (state: { username: string }, { rejectWithValue }) => {
    const { username } = state;
    try {
      const response = await authorizedRequest.get(`users/${username}`);
      return response;
    } catch (e: any) {
      return rejectWithValue(e.message);
    }
  }
);

const studentsSlice = createSlice({
  name: "students",
  initialState,
  reducers: {},
  extraReducers: {
    // student list
    [getStudentList.pending.toString()]: (state) => {
      state.loading = true;
    },
    [getStudentList.fulfilled.toString()]: (
      state,
      action: PayloadAction<{ items: IUser[]; totalPage: number }>
    ) => {
      state.loading = false;
      state.studentList = { ...action.payload };
    },
    [getStudentList.rejected.toString()]: (state) => {
      state.loading = false;
    },

    // student detail
    [getStudentDetail.pending.toString()]: (state) => {
      state.loading = true;
    },
    [getStudentDetail.fulfilled.toString()]: (
      state,
      action: PayloadAction<IAdmin>
    ) => {
      state.loading = false;
      state.studentDetail = action.payload;
    },
    [getStudentDetail.rejected.toString()]: (state) => {
      state.loading = false;
    }
  }
});

export default studentsSlice.reducer;
