import { useState } from "react";
import IconButton from "@mui/material/IconButton";
import Divider from "@mui/material/Divider";
import List from "@mui/material/List";
import Collapse from "@mui/material/Collapse";
import ListItemText from "@mui/material/ListItemText";
import ListItemIcon from "@mui/material/ListItemIcon";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faChevronLeft,
  faChevronRight,
  faChevronUp,
  faChevronDown
} from "@fortawesome/free-solid-svg-icons";
import { NavLink, useLocation } from "react-router-dom";
import clsx from "clsx";

import logoImg from "images/logo.png";
import logoSquaredImg from "images/logo-squared.png";
import {
  StyledDrawerFooter,
  StyledDrawer,
  StyledDrawerHeader,
  StyledListItem
} from "./Sidebar.styles";
import { navLinks } from "./constants";
import styles from "./Sidebar.module.css";

const { logo, logoSquared, navIcon, link, activeLink } = styles;

interface SidebarProps {
  open: boolean;
  onToggleSidebar: () => void;
}

export default function Sidebar({ open, onToggleSidebar }: SidebarProps) {
  const [isCollapsed, setIsCollapsed] = useState<boolean>(false);
  const location = useLocation();

  const handleClickToggleDrawer = () => {
    onToggleSidebar();
    !isCollapsed && setIsCollapsed(false);
  };
  const handleClickCollapse = () => {
    setIsCollapsed(!isCollapsed);
    if (!open) {
      onToggleSidebar();
    }
  };

  return (
    <StyledDrawer variant="permanent" open={open}>
      <StyledDrawerHeader>
        <img
          className={clsx(open ? logo : logoSquared)}
          src={open ? logoImg : logoSquaredImg}
          alt="logo"
        />
      </StyledDrawerHeader>
      <Divider />
      <List sx={{ paddingTop: 2, paddingBottom: 2 }}>
        {navLinks.map((nav) => {
          if (nav.subLinks) {
            return (
              <>
                <StyledListItem
                  key={nav.title}
                  button
                  sx={
                    location.pathname.includes("property-management") && {
                      backgroundColor: "#5749f7"
                    }
                  }
                  onClick={handleClickCollapse}
                >
                  <ListItemIcon>
                    <FontAwesomeIcon className={navIcon} icon={nav.icon} />
                  </ListItemIcon>
                  <ListItemText primary={nav.title} sx={{ color: "#ffffff" }} />
                  <IconButton
                    sx={{ color: "#ffffff" }}
                    size="small"
                    onClick={handleClickCollapse}
                  >
                    <FontAwesomeIcon
                      icon={isCollapsed && open ? faChevronDown : faChevronUp}
                    />
                  </IconButton>
                </StyledListItem>
                <Collapse in={isCollapsed && open} timeout="auto" unmountOnExit>
                  <List component="div" disablePadding>
                    {nav.subLinks.map((sub) => (
                      <NavLink
                        className={(navData) =>
                          navData.isActive ? activeLink : link
                        }
                        key={sub.title}
                        to={sub.path}
                      >
                        <StyledListItem
                          button
                          sx={{ display: "flex", alignItems: "center" }}
                        >
                          <ListItemIcon>
                            <FontAwesomeIcon
                              className={navIcon}
                              icon={sub.icon}
                            />
                          </ListItemIcon>

                          <ListItemText
                            primary={sub.title}
                            sx={{ color: "#ffffff" }}
                          />
                        </StyledListItem>
                      </NavLink>
                    ))}
                  </List>
                </Collapse>
              </>
            );
          }
          return (
            <NavLink
              className={(navData) => (navData.isActive ? activeLink : link)}
              key={nav.title}
              to={nav.path}
            >
              <StyledListItem
                button
                sx={{ display: "flex", alignItems: "center" }}
              >
                <ListItemIcon>
                  <FontAwesomeIcon className={navIcon} icon={nav.icon} />
                </ListItemIcon>
                <ListItemText primary={nav.title} sx={{ color: "#ffffff" }} />
              </StyledListItem>
            </NavLink>
          );
        })}
      </List>
      <StyledDrawerFooter>
        <IconButton onClick={handleClickToggleDrawer} sx={{ color: "#ffffff" }}>
          <FontAwesomeIcon icon={open ? faChevronRight : faChevronLeft} />
        </IconButton>
      </StyledDrawerFooter>
    </StyledDrawer>
  );
}
