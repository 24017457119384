import {
  faHome,
  faUser,
  faUserGraduate,
  faBriefcase,
  faChalkboardTeacher,
  faTasks,
  faQuestionCircle,
  faCircle
} from "@fortawesome/free-solid-svg-icons";

import {
  PATH_ADMIN_MANAGEMENT,
  PATH_BUSINESS_MANAGEMENT,
  PATH_DASHBOARD,
  PATH_EXAM_TYPE_MANAGEMENT,
  PATH_LEVEL_MANAGEMENT,
  PATH_PAPER_MANAGEMENT,
  PATH_QUESTION_MANAGEMENT,
  PATH_STUDENT_MANAGEMENT,
  PATH_SUBJECT_MANAGEMENT,
  PATH_TEACHME_MANAGEMENT,
  PATH_TOPIC_MANAGEMENT,
  PATH_YEAR_MANAGEMENT
} from "router/router.paths";

export const navLinks = [
  {
    title: "Dashboard",
    path: PATH_DASHBOARD,
    icon: faHome
  },
  {
    title: "Admin",
    path: PATH_ADMIN_MANAGEMENT,
    icon: faUser
  },
  {
    title: "Student",
    path: PATH_STUDENT_MANAGEMENT,
    icon: faUserGraduate
  },
  {
    title: "Business",
    path: PATH_BUSINESS_MANAGEMENT,
    icon: faBriefcase
  },
  {
    title: "TeachMe",
    path: PATH_TEACHME_MANAGEMENT,
    icon: faChalkboardTeacher
  },
  {
    title: "Property",
    path: "/app/property-management",
    icon: faTasks,
    subLinks: [
      {
        title: "Topic",
        path: PATH_TOPIC_MANAGEMENT,
        icon: faCircle
      },
      {
        title: "Paper",
        path: PATH_PAPER_MANAGEMENT,
        icon: faCircle
      },
      {
        title: "Level",
        path: PATH_LEVEL_MANAGEMENT,
        icon: faCircle
      },
      {
        title: "Year",
        path: PATH_YEAR_MANAGEMENT,
        icon: faCircle
      },
      {
        title: "Subject",
        path: PATH_SUBJECT_MANAGEMENT,
        icon: faCircle
      },
      {
        title: "Exam Type",
        path: PATH_EXAM_TYPE_MANAGEMENT,
        icon: faCircle
      }
    ]
  },
  {
    title: "Question",
    path: PATH_QUESTION_MANAGEMENT,
    icon: faQuestionCircle
  }
];
