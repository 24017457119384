import { styled } from "@mui/material/styles";
import Chip from "@mui/material/Chip";

export const StyledChip: any = styled(Chip, {
  shouldForwardProp: (prop) => prop !== "color"
})(({ theme, color }) => ({
  textTransform: "uppercase",
  color: "#ffffff",
  borderColor: color,
  backgroundColor: color
}));
