import { useState } from "react";
import { Outlet } from "react-router-dom";
import Box from "@mui/material/Box";

import { Sidebar, Header, Footer } from "components";
import { StyledMain } from "./AppLayout.styles";

export default function AppLayout() {
  const [isOpen, setIsOpen] = useState<boolean>(true);

  const handleClickToggleDrawer = () => {
    setIsOpen(!isOpen);
  };

  return (
    <Box sx={{ display: "flex" }}>
      <Sidebar open={isOpen} onToggleSidebar={handleClickToggleDrawer} />
      <Box sx={{ flexGrow: 1 }}>
        <Header open={isOpen} />
        <StyledMain>
          <Outlet />
        </StyledMain>
        <Footer />
      </Box>
    </Box>
  );
}
