import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";

import { authorizedRequest } from "services/request";
import { IBusiness, IBusinessUser } from "types/business.model";
import { IUser } from "types/user.model";

interface InitialStateType {
  loading: boolean;
  businessList: {
    items: IBusiness[];
    totalPage: number;
  };
  businessDetail: IBusiness;
  businessUserList: IUser[];
  individualStudentList: {
    items: IUser[];
    totalPage: number;
  };
  businessUserDetail: IBusinessUser;
}

const initialState: InitialStateType = {
  loading: false,
  businessList: {
    items: [],
    totalPage: 1
  },
  businessDetail: {
    companyId: "",
    createdAt: "",
    id: "",
    image: "",
    name: "",
    status: "",
    type: ""
  },
  businessUserList: [],
  individualStudentList: {
    items: [],
    totalPage: 1
  },
  businessUserDetail: {
    id: "",
    active: false,
    address: "",
    birthday: "",
    email: "",
    emailVerified: false,
    fullName: "",
    gender: "",
    groups: "",
    status: "",
    updatedAt: "",
    userName: "",
    phoneNumber: ""
  }
};

export const getBusinessList = createAsyncThunk(
  "admins/getBusinessList",
  async (state: { page: number }, { rejectWithValue }) => {
    const { page } = state;
    let params = {};
    params = { limit: 10, page };
    if (page === -1) {
      params = { all: true };
    }
    try {
      const response = (await authorizedRequest.get("business", {
        params
      })) as any;
      return response;
    } catch (e: any) {
      return rejectWithValue(e.message);
    }
  }
);

export const getBusinessDetail = createAsyncThunk(
  "admins/getBusinessDetail",
  async (state: { id: string }, { rejectWithValue }) => {
    const { id } = state;
    try {
      const response = await authorizedRequest.get(`business/${id}`);
      return response;
    } catch (e: any) {
      return rejectWithValue(e.message);
    }
  }
);

export const getBusinessUserList = createAsyncThunk(
  "admins/getBusinessUserList",
  async (
    state: { id: string; group: string; page: number },
    { rejectWithValue }
  ) => {
    const { id, group, page } = state;
    const params = { limit: 10, group, page };

    try {
      const response = (await authorizedRequest.get(
        `business/list-users/${id}`,
        { params }
      )) as any;
      return response.items;
    } catch (e: any) {
      return rejectWithValue(e.message);
    }
  }
);

export const getBusinessUserDetail = createAsyncThunk(
  "admins/getBusinessUserDetail",
  async (state: { username: string }, { rejectWithValue }) => {
    const { username } = state;
    try {
      const response = await authorizedRequest.get(`users/${username}`);
      return response;
    } catch (e: any) {
      return rejectWithValue(e.message);
    }
  }
);

export const getIndividualStudentList = createAsyncThunk(
  "admins/getIndividualStudentList",
  async (state: { page: number }, { rejectWithValue }) => {
    const { page } = state;
    const params = { limit: 10, type: "Individual", page };

    try {
      const response = (await authorizedRequest.get("business/list-users", {
        params
      })) as any;
      return response;
    } catch (e: any) {
      return rejectWithValue(e.message);
    }
  }
);

const businessSlice = createSlice({
  name: "business",
  initialState,
  reducers: {},
  extraReducers: {
    // business list
    [getBusinessList.pending.toString()]: (state) => {
      state.loading = true;
    },
    [getBusinessList.fulfilled.toString()]: (
      state,
      action: PayloadAction<{ items: IBusiness[]; totalPage: 1 }>
    ) => {
      state.loading = false;
      state.businessList = { ...action.payload };
    },
    [getBusinessList.rejected.toString()]: (state) => {
      state.loading = false;
    },

    // business detail
    [getBusinessDetail.pending.toString()]: (state) => {
      state.loading = true;
    },
    [getBusinessDetail.fulfilled.toString()]: (
      state,
      action: PayloadAction<IBusiness>
    ) => {
      state.loading = false;
      state.businessDetail = action.payload;
    },
    [getBusinessDetail.rejected.toString()]: (state) => {
      state.loading = false;
    },

    // business users
    [getBusinessUserList.pending.toString()]: (state) => {
      state.loading = true;
    },
    [getBusinessUserList.fulfilled.toString()]: (
      state,
      action: PayloadAction<IUser[]>
    ) => {
      state.loading = false;
      state.businessUserList = [...action.payload];
    },
    [getBusinessUserList.rejected.toString()]: (state) => {
      state.loading = false;
    },

    // business user detail
    [getBusinessUserDetail.pending.toString()]: (state) => {
      state.loading = true;
    },
    [getBusinessUserDetail.fulfilled.toString()]: (
      state,
      action: PayloadAction<IBusinessUser>
    ) => {
      state.loading = false;
      state.businessUserDetail = action.payload;
    },
    [getBusinessUserDetail.rejected.toString()]: (state) => {
      state.loading = false;
    },

    // individual students
    [getIndividualStudentList.pending.toString()]: (state) => {
      state.loading = true;
    },
    [getIndividualStudentList.fulfilled.toString()]: (
      state,
      action: PayloadAction<{ items: IUser[]; totalPage: 1 }>
    ) => {
      state.loading = false;
      state.individualStudentList = { ...action.payload };
    },
    [getIndividualStudentList.rejected.toString()]: (state) => {
      state.loading = false;
    }
  }
});

export default businessSlice.reducer;
