import { useRef } from "react";
import SunEditor from "suneditor-react";
import SunEditorCore from "suneditor/src/lib/core";
import InputLabel from "@mui/material/InputLabel";
import FormHelperText from "@mui/material/FormHelperText";
import Box from "@mui/material/Box";
import katex from "katex";
import { isEmpty } from "lodash";

import { Button } from "../Button";
import { labelStyles, asteriskStyles } from "./Editor.styles";
import { buttonList } from "./Editor.constants";

interface EditorProps {
  required?: boolean;
  errorMessage?: string;
  label?: string;
  name?: string;
  removable?: boolean;
  value: string;
  initEditor?: (sunEditor: SunEditorCore) => void;
  onChange: (content: string, plainText: string) => void;
  onRemove?: () => void;
}

export default function Editor({
  required,
  label,
  errorMessage,
  removable,
  value,
  name,
  onRemove,
  onChange,
  initEditor
}: EditorProps) {
  const editor = useRef<SunEditorCore>();

  const getSunEditorInstance = (sunEditor: SunEditorCore) => {
    initEditor?.(sunEditor);
    editor.current = sunEditor;
  };

  const handleChange = (content: string) => {
    const text = editor?.current?.getText();
    onChange(content, text || "");
  };

  return (
    <div className="w-full">
      <Box
        sx={
          removable
            ? {
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                marginBottom: 1
              }
            : {}
        }
      >
        <InputLabel error={!isEmpty(errorMessage)} sx={labelStyles}>
          {label}{" "}
          {required && (
            <Box component="span" sx={asteriskStyles}>
              *
            </Box>
          )}
        </InputLabel>

        {removable && (
          <Button
            variant="contained"
            color="error"
            onClick={onRemove}
            sx={{ fontSize: "10px" }}
          >
            Remove
          </Button>
        )}
      </Box>

      <SunEditor
        setContents={value === "<p><br></p>" ? "" : value}
        defaultValue={""}
        getSunEditorInstance={getSunEditorInstance}
        setOptions={{
          buttonList
          // katex
        }}
        name={name}
        setDefaultStyle="font-size:16px;"
        onChange={handleChange}
      />
      {!isEmpty(errorMessage) && (
        <FormHelperText error sx={{ margin: "4px 14px 0px 14px" }}>
          {errorMessage}
        </FormHelperText>
      )}
    </div>
  );
}

Editor.defaultProps = {
  required: false,
  removable: false,
  errorMessage: "",
  label: "",
  onRemove: (index: number) => {},
  name: "",
  initEditor: (sun: SunEditorCore) => {}
};
