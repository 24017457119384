import { toast } from "react-toastify";
import qs from "qs";
import { isEmpty } from "lodash";

export const showMessage = (type: "success" | "error", message: string) => {
  switch (type) {
    case "success":
      return toast.success(message, { theme: "colored" });
    case "error":
      return toast.error(message, { theme: "colored" });
    default:
      return toast(message, { theme: "colored" });
  }
};

export const generatePassword = (length: number) => {
  const charset =
    "abcdefghijk^&@^#&!%#(lmno012345678pqrstuv012345678wxyzABCDEFGHIJKLMNOPQRST^&@^#&!%#(UVWXYZ0123456789^&@^#&!%#()";
  let retVal = "";
  for (let i = 0, n = charset.length; i < length; ++i) {
    retVal += charset.charAt(Math.floor(Math.random() * n));
  }
  return `${retVal}1A#`;
};

export const paramsSerializer = (params: any) => qs.stringify(params);

export const getChangedData = (current: any, updated: any) => {
  const data: any = {};
  for (const updatedKey in updated) {
    if (!!updatedKey && updated[updatedKey] !== current[updatedKey]) {
      data[updatedKey] = updated[updatedKey];
    }
  }
  return data;
};

export const simplifyString = (str: string) => {
  let coppyString: string = str;
  coppyString = coppyString.toLowerCase();
  coppyString = coppyString.replace(/ /g, "");
  coppyString = coppyString.replace(/ + /g, "");
  return coppyString;
};

export const getBinaryFromFile = (file: File) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();

    reader.addEventListener("load", () => resolve(reader.result));
    reader.addEventListener("error", (err) => reject(err));

    reader.readAsArrayBuffer(file);
  });

export const removeEmptyKeys = (obj: any) => {
  Object.keys(obj).forEach((key) => {
    if (obj[key] === "") {
      delete obj[key];
    }
  });

  return obj;
};

export const formatMathpix = (str: string) => {
  if (isEmpty(str)) return "";
  return str
    .replace(/\n/g, " \\\\ ")
    .replaceAll("\\(", "")
    .replaceAll("\\)", "")
    .replaceAll(" ", " \\ ")
    .replaceAll("\\[", "")
    .replaceAll("\\]", "");
};

export const extractContentHtml = (htmlString: string) => {
  let plainText = htmlString.replace(/<[^>]+>|&nbsp;|<code>|<\/code>/g, "");
  if (htmlString) {
    plainText = `<p>${htmlString}</p>`;
    return plainText;
  }
  return "";
};

export const changeEditorToSteps = (value: string) => {
  const answerText = value.replace(/<code>|<\/code>/g, "");
  let answerBySteps = answerText.split(
    // /<\/p>|<\/math>|<math xmlns=\"http:\/\/www.w3.org\/1998\/Math\/MathML\">/g
    /<\/p>/g
  );
  if (answerBySteps && answerBySteps.length > 0) {
    answerBySteps = answerBySteps
      .map((val) => {
        if (val) {
          return val.replace(/<\/?p>/g, "");
        }
        return val;
      })
      .filter((val) => val !== "");
  }

  // .replace(/<br>/g, "")
  // .match(/<p>|<\/p>/g);
  // .match(/<[^>]+>[^<]*<\/[^>]+>/g);
  // if (answerBySteps && answerBySteps.length > 0) {
  //   answerBySteps = answerBySteps.filter((item) => item !== "<br></p>");
  // }
  return answerBySteps;
};

export const nonEmptyObject = (obj: any) => {
  for (const propName in obj) {
    if (
      obj[propName] === null ||
      obj[propName] === undefined ||
      obj[propName] === ""
    ) {
      delete obj[propName];
    }
  }
  return obj;
};
