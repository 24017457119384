import { ReactNode, ChangeEvent, useState, useEffect } from "react";
import MuiTable from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import CircularProgress from "@mui/material/CircularProgress";
import Pagination from "@mui/material/Pagination";
import Grid from "@mui/material/Grid";
import { useNavigate, useLocation } from "react-router-dom";
import {
  DragDropContext,
  DropResult,
  Droppable,
  DroppableProvided,
  ResponderProvided
} from "react-beautiful-dnd";

const queryString = require("query-string");

interface DraggableTableProps {
  loading?: boolean;
  pagination?: boolean;
  head: ReactNode;
  colSpan: number;
  data: any[];
  renderRows: Function;
  totalPage: number;
  onDragEnd: (result: DropResult, provided: ResponderProvided) => void;
}

export default function DraggableTable({
  loading,
  pagination,
  head,
  data,
  colSpan,
  renderRows,
  totalPage,
  onDragEnd
}: DraggableTableProps) {
  const [currentPage, setCurrentPage] = useState<number>(1);
  const navigate = useNavigate();
  const location = useLocation();

  const locationSearch = queryString.parse(location.search);

  useEffect(() => {
    if (locationSearch.page) {
      setCurrentPage(Number(locationSearch.page));
    }
  }, [locationSearch.page]);

  const renderTableBody = () => {
    if (loading) {
      return (
        <TableRow>
          <TableCell
            align="center"
            style={{ border: "none" }}
            colSpan={colSpan}
          >
            <CircularProgress />
          </TableCell>
        </TableRow>
      );
    }
    if (data?.length === 0 || !data) {
      return (
        <TableRow>
          <TableCell
            align="center"
            style={{ border: "none" }}
            colSpan={colSpan}
          >
            <Typography variant="body1" sx={{ color: "#333333" }}>
              No data
            </Typography>
          </TableCell>
        </TableRow>
      );
    }
    return data.map((item: any, index: number) => renderRows(item, index));
  };

  const handleChangePage = (event: ChangeEvent<unknown>, page: number) => {
    setCurrentPage(page);
    navigate(`${location.pathname}?page=${page}`);
  };

  return (
    <TableContainer component={Paper}>
      <MuiTable>
        <TableHead>
          <TableRow>{head}</TableRow>
        </TableHead>

        <DragDropContext onDragEnd={onDragEnd}>
          <Droppable droppableId="none">
            {(provided: DroppableProvided) => (
              <TableBody {...provided.droppableProps} ref={provided.innerRef}>
                {renderTableBody()}
                {provided.placeholder}
              </TableBody>
            )}
          </Droppable>
        </DragDropContext>
      </MuiTable>
      {pagination && (
        <Grid container justifyContent="flex-end" sx={{ padding: 2 }}>
          <Pagination
            count={totalPage}
            page={currentPage}
            variant="outlined"
            color="primary"
            onChange={handleChangePage}
          />
        </Grid>
      )}
    </TableContainer>
  );
}

DraggableTable.defaultProps = {
  loading: false,
  pagination: true
};
