import { ReactNode } from "react";
import {
  Draggable,
  DraggableProvided,
  DraggingStyle,
  NotDraggingStyle
} from "react-beautiful-dnd";
import TableRow from "@mui/material/TableRow";

interface DraggbleItemProps {
  id: string;
  index: any;
  children: ReactNode;
}

export default function DraggableRow({
  id,
  index,
  children
}: DraggbleItemProps) {
  const getItemStyle = (
    isDragging: boolean,
    draggableStyle?: DraggingStyle | NotDraggingStyle
  ) => ({
    cursor: isDragging ? "all-scroll" : "pointer",
    ...draggableStyle
  });

  return (
    <Draggable key={id} draggableId={id} index={index}>
      {(draggableProvided: DraggableProvided, snapshot: any) => (
        <TableRow
          {...draggableProvided.draggableProps}
          {...draggableProvided.dragHandleProps}
          ref={draggableProvided.innerRef}
          style={{
            ...getItemStyle(
              snapshot.isDragging,
              draggableProvided.draggableProps.style
            ),
            backgroundColor: "#ffffff"
          }}
        >
          {children}
        </TableRow>
      )}
    </Draggable>
  );
}
