import { styled } from "@mui/material/styles";

export const StyledFooter = styled("footer")(({ theme }) => ({
  height: 63,
  paddingLeft: theme.spacing(2),
  paddingRight: theme.spacing(2),
  display: "flex",
  alignItems: "center",
  borderTop: "1px solid #cecece"
}));
