import { StandardTextFieldProps } from "@mui/material/TextField";
import InputLabel from "@mui/material/InputLabel";
import Box from "@mui/material/Box";

import {
  StyledTextField,
  labelStyles,
  asteriskStyles
} from "./FormControl.styles";

export interface InputProps extends StandardTextFieldProps {
  label?: string;
  errorMessage?: string;
  required?: boolean;
  min?: number;
}

export default function Input({
  label,
  errorMessage,
  required,
  className,
  min,
  ...props
}: InputProps) {
  return (
    <div className={className}>
      <InputLabel error={Boolean(errorMessage)} sx={labelStyles}>
        {label}{" "}
        {required && (
          <Box component="span" sx={asteriskStyles}>
            *
          </Box>
        )}
      </InputLabel>

      <StyledTextField
        error={Boolean(errorMessage)}
        helperText={errorMessage}
        size="small"
        min={min}
        {...props}
      />
    </div>
  );
}

Input.defaultProps = {
  label: "",
  errorMessage: "",
  required: false,
  min: 0
};
