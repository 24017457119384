import { Routes, Route, Navigate } from "react-router-dom";
import Loadable from "@loadable/component";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "react-phone-number-input/style.css";
import "suneditor/dist/css/suneditor.min.css";
import "katex/dist/katex.min.css";
import "cropperjs/dist/cropper.css";
import Amplify from "aws-amplify";

import Theme from "theme";
import { AuthLayout, AppLayout } from "layout";
import {
  PATH_APP_BASE,
  PATH_AUTH_BASE,
  PATH_DASHBOARD,
  PATH_LOGIN
} from "router/router.paths";

import { authRoutes, appRoutes, redirectRoutes } from "router/router.routes";
import { awsConfig } from "constants/config";

Amplify.configure({
  Auth: awsConfig.Auth
});

const NotFound = Loadable(() => import("pages/NotFound"));

function App() {
  const renderRoutes = (routes: typeof authRoutes) =>
    routes.map((route) => (
      <Route key={route.path} path={route.path} element={route.element} />
    ));

  const renderRedirectRoutes = (routes: typeof redirectRoutes) =>
    routes.map((route) => (
      <Route
        key={route.original}
        path={route.original}
        element={<Navigate replace to={route.redirect} />}
      />
    ));

  return (
    <Theme>
      <div className="App">
        <ToastContainer
          position="top-right"
          autoClose={3000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover={false}
        />
        <Routes>
          {/** Not found page */}
          <Route path="*" element={<NotFound />} />
          {/** Auth Layout */}
          <Route path={PATH_AUTH_BASE} element={<AuthLayout />}>
            <Route
              path={PATH_AUTH_BASE}
              element={<Navigate replace to={PATH_LOGIN} />}
            />
            {renderRoutes(authRoutes)}
          </Route>
          {/** App Layout */}
          <Route path={PATH_APP_BASE} element={<AppLayout />}>
            {renderRedirectRoutes(redirectRoutes)}

            {renderRoutes(appRoutes)}
          </Route>
          <Route path="/" element={<Navigate replace to={PATH_DASHBOARD} />} />
        </Routes>
      </div>
    </Theme>
  );
}

export default App;
