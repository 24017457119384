import Typography from "@mui/material/Typography";

import { StyledFooter } from "./Footer.styles";

export default function Footer() {
  const date = new Date();
  const currentYear = date.getFullYear();

  return (
    <StyledFooter>
      <Typography variant="body2" sx={{ color: "#333333" }}>
        &copy; {currentYear} Copyrighted by Learning Anywhere
      </Typography>
    </StyledFooter>
  );
}
