export const goBackStyles = {
  marginBottom: 2
};

export const paperStyles = {
  padding: 1
};

export const buttonStyles = {
  "& > svg": { marginRight: 1 }
};
