import Loadable from "@loadable/component";

import {
  PATH_LOGIN,
  PATH_FORGOT_PASSWORD,
  PATH_VERIFY_EMAIL,
  PATH_DASHBOARD,
  PATH_ADMIN_CREATE,
  PATH_ADMIN_EDIT,
  PATH_STUDENT_CREATE,
  PATH_STUDENT_EDIT,
  PATH_BUSINESS_CREATE,
  PATH_BUSINESS_EDIT,
  PATH_BUSINESS_EDIT_ADD_USER,
  PATH_BUSINESS_CREATE_USER,
  PATH_BUSINESS_EDIT_USER,
  PATH_TEACHME_CREATE,
  PATH_TEACHME_EDIT,
  PATH_QUESTION_CREATE,
  PATH_QUESTION_EDIT,
  PATH_ADMIN_PROFILE,
  PATH_ADMIN_MANAGEMENT_LIST,
  PATH_STUDENT_MANAGEMENT_LIST,
  PATH_BUSINESS_MANAGEMENT_LIST,
  PATH_TEACHME_MANAGEMENT_LIST,
  PATH_TOPIC_MANAGEMENT_LIST,
  PATH_PAPER_MANAGEMENT_LIST,
  PATH_LEVEL_MANAGEMENT_LIST,
  PATH_YEAR_MANAGEMENT_LIST,
  PATH_SUBJECT_MANAGEMENT_LIST,
  PATH_EXAM_TYPE_MANAGEMENT_LIST,
  PATH_QUESTION_MANAGEMENT_LIST,
  PATH_ADMIN_MANAGEMENT,
  PATH_APP_BASE,
  PATH_STUDENT_MANAGEMENT,
  PATH_BUSINESS_MANAGEMENT,
  PATH_TEACHME_MANAGEMENT,
  PATH_TOPIC_MANAGEMENT,
  PATH_PAPER_MANAGEMENT,
  PATH_LEVEL_MANAGEMENT,
  PATH_YEAR_MANAGEMENT,
  PATH_SUBJECT_MANAGEMENT,
  PATH_EXAM_TYPE_MANAGEMENT,
  PATH_QUESTION_MANAGEMENT
} from "./router.paths";

const Dashboard = Loadable(() => import("pages/app/Dashboard"));
const AdminManagement = Loadable(() => import("pages/app/AdminManagement"));
const AdminCreate = Loadable(
  () => import("pages/app/AdminManagement/AdminCreate")
);
const AdminEdit = Loadable(() => import("pages/app/AdminManagement/AdminEdit"));
const StudentManagement = Loadable(() => import("pages/app/StudentManagement"));
const StudentCreate = Loadable(
  () => import("pages/app/StudentManagement/StudentCreate")
);
const StudentEdit = Loadable(
  () => import("pages/app/StudentManagement/StudentEdit")
);
const BusinessManagement = Loadable(
  () => import("pages/app/BusinessManagement")
);
const BusinessCreate = Loadable(
  () => import("pages/app/BusinessManagement/BusinessCreate")
);
const BusinessEdit = Loadable(
  () => import("pages/app/BusinessManagement/BusinessEdit")
);
const BusinessEditAddUser = Loadable(
  () => import("pages/app/BusinessManagement/AddStudentToBusiness")
);
const BusinessCreateUser = Loadable(
  () => import("pages/app/BusinessManagement/BusinessCreateUser")
);
const BusinessEditUser = Loadable(
  () => import("pages/app/BusinessManagement/BusinessEditUser")
);
const TeachMeManagement = Loadable(() => import("pages/app/TeachMeManagement"));
const TeachMeCreate = Loadable(
  () => import("pages/app/TeachMeManagement/TeachMeCreate")
);
const TeachMeEdit = Loadable(
  () => import("pages/app/TeachMeManagement/TeachMeEdit")
);
const TopicManagement = Loadable(() => import("pages/app/TopicManagement"));
const PaperManagement = Loadable(() => import("pages/app/PaperManagement"));
const LevelManagement = Loadable(() => import("pages/app/LevelManagement"));
const YearManagement = Loadable(() => import("pages/app/YearManagement"));
const SubjectManagement = Loadable(() => import("pages/app/SubjectManagement"));
const ExamTypeManagement = Loadable(
  () => import("pages/app/ExamTypeManagement")
);
const QuestionManagement = Loadable(
  () => import("pages/app/QuestionManagement")
);
const QuestionCreate = Loadable(
  () => import("pages/app/QuestionManagement/QuestionCreate")
);
const QuestionEdit = Loadable(
  () => import("pages/app/QuestionManagement/QuestionEdit")
);
const AdminProfile = Loadable(() => import("pages/app/AdminProfile"));

const Login = Loadable(() => import("pages/auth/Login"));
const ForgotPassword = Loadable(() => import("pages/auth/ForgotPassword"));
const VerifyEmail = Loadable(() => import("pages/auth/VerifyEmail"));

export const appRoutes = [
  {
    name: "Dashboard",
    path: PATH_DASHBOARD,
    element: <Dashboard />
  },
  {
    name: "Admin Management",
    path: PATH_ADMIN_MANAGEMENT_LIST,
    element: <AdminManagement />
  },
  {
    name: "Admin Management Create",
    path: PATH_ADMIN_CREATE,
    element: <AdminCreate />
  },
  {
    name: "Admin Management Edit",
    path: PATH_ADMIN_EDIT,
    element: <AdminEdit />
  },
  {
    name: "Student Management",
    path: PATH_STUDENT_MANAGEMENT_LIST,
    element: <StudentManagement />
  },
  {
    name: "Student Management Create",
    path: PATH_STUDENT_CREATE,
    element: <StudentCreate />
  },
  {
    name: "Student Management Edit",
    path: PATH_STUDENT_EDIT,
    element: <StudentEdit />
  },
  {
    name: "Business Management",
    path: PATH_BUSINESS_MANAGEMENT_LIST,
    element: <BusinessManagement />
  },
  {
    name: "Business Management Create",
    path: PATH_BUSINESS_CREATE,
    element: <BusinessCreate />
  },
  {
    name: "Business Management Edit",
    path: PATH_BUSINESS_EDIT,
    element: <BusinessEdit />
  },
  {
    name: "Business Management Edit Add User",
    path: PATH_BUSINESS_EDIT_ADD_USER,
    element: <BusinessEditAddUser />
  },
  {
    name: "Business Management Create User",
    path: PATH_BUSINESS_CREATE_USER,
    element: <BusinessCreateUser />
  },
  {
    name: "Business Management Edit User",
    path: PATH_BUSINESS_EDIT_USER,
    element: <BusinessEditUser />
  },
  {
    name: "TeachMe Management",
    path: PATH_TEACHME_MANAGEMENT_LIST,
    element: <TeachMeManagement />
  },
  {
    name: "TeachMe Management Create",
    path: PATH_TEACHME_CREATE,
    element: <TeachMeCreate />
  },
  {
    name: "TeachMe Management Edit",
    path: PATH_TEACHME_EDIT,
    element: <TeachMeEdit />
  },
  {
    name: "Topic Management",
    path: PATH_TOPIC_MANAGEMENT_LIST,
    element: <TopicManagement />
  },
  {
    name: "Paper Management",
    path: PATH_PAPER_MANAGEMENT_LIST,
    element: <PaperManagement />
  },
  {
    name: "Level Management",
    path: PATH_LEVEL_MANAGEMENT_LIST,
    element: <LevelManagement />
  },
  {
    name: "Year Management",
    path: PATH_YEAR_MANAGEMENT_LIST,
    element: <YearManagement />
  },
  {
    name: "Subject Management",
    path: PATH_SUBJECT_MANAGEMENT_LIST,
    element: <SubjectManagement />
  },
  {
    name: "Exam Type Management",
    path: PATH_EXAM_TYPE_MANAGEMENT_LIST,
    element: <ExamTypeManagement />
  },
  {
    name: "Question Management",
    path: PATH_QUESTION_MANAGEMENT_LIST,
    element: <QuestionManagement />
  },
  {
    name: "Question Management Create",
    path: PATH_QUESTION_CREATE,
    element: <QuestionCreate />
  },
  {
    name: "Question Management Edit",
    path: PATH_QUESTION_EDIT,
    element: <QuestionEdit />
  },
  {
    name: "Admin Profile",
    path: PATH_ADMIN_PROFILE,
    element: <AdminProfile />
  }
];

export const authRoutes = [
  {
    name: "Login",
    path: PATH_LOGIN,
    element: <Login />
  },
  {
    name: "Forgot Password",
    path: PATH_FORGOT_PASSWORD,
    element: <ForgotPassword />
  },
  {
    name: "Verify Email",
    path: PATH_VERIFY_EMAIL,
    element: <VerifyEmail />
  }
];

export const redirectRoutes = [
  {
    original: PATH_APP_BASE,
    redirect: PATH_DASHBOARD
  },
  {
    original: PATH_ADMIN_MANAGEMENT,
    redirect: PATH_ADMIN_MANAGEMENT_LIST
  },
  {
    original: PATH_STUDENT_MANAGEMENT,
    redirect: PATH_STUDENT_MANAGEMENT_LIST
  },
  {
    original: PATH_BUSINESS_MANAGEMENT,
    redirect: PATH_BUSINESS_MANAGEMENT_LIST
  },
  {
    original: PATH_TEACHME_MANAGEMENT,
    redirect: PATH_TEACHME_MANAGEMENT_LIST
  },
  {
    original: PATH_TOPIC_MANAGEMENT,
    redirect: PATH_TOPIC_MANAGEMENT_LIST
  },
  {
    original: PATH_LEVEL_MANAGEMENT,
    redirect: PATH_LEVEL_MANAGEMENT_LIST
  },
  {
    original: PATH_PAPER_MANAGEMENT,
    redirect: PATH_PAPER_MANAGEMENT_LIST
  },
  {
    original: PATH_SUBJECT_MANAGEMENT,
    redirect: PATH_SUBJECT_MANAGEMENT_LIST
  },
  {
    original: PATH_EXAM_TYPE_MANAGEMENT,
    redirect: PATH_EXAM_TYPE_MANAGEMENT_LIST
  },
  {
    original: PATH_QUESTION_MANAGEMENT,
    redirect: PATH_QUESTION_MANAGEMENT_LIST
  },
  {
    original: PATH_YEAR_MANAGEMENT,
    redirect: PATH_YEAR_MANAGEMENT_LIST
  }
];
