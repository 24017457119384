import React from "react";
import parse from "html-react-parser";
import MathJax from "./MathjaxParser";

interface ParserProps {
  mString: string;
}

const Parser = ({ mString }: ParserProps) => {
  const iparser = new DOMParser();
  const parsedHtml = iparser.parseFromString(mString, "text/html");
  const itemsArray: any[] = [];
  if (!parsedHtml || !parsedHtml.body) return null;

  parsedHtml.body.childNodes.forEach((item: any) => {
    if (!item?.outerHTML) return;
    if (item.innerHTML.includes('xmlns="http://www.w3.org/1998/Math/MathML')) {
      item.childNodes.forEach((child: any) => {
        if (child.tagName?.includes("math")) {
          itemsArray.push({
            type: "math",
            html: child.outerHTML
          });
        } else {
          itemsArray.push({
            type: "normal",
            html: `<p>${child.textContent}</>`
          });
        }
      });
    } else {
      itemsArray.push({ type: "normal", html: item.outerHTML });
    }
  });
  return (
    <div className="cursor-pointer relative html-parser">
      {itemsArray.map((item, index) =>
        item.type === "math" ? (
          <MathJax key={index} mString={item.html} />
        ) : (
          <div key={index}>{parse(item.html)}</div>
        )
      )}
    </div>
  );
};

export default Parser;
