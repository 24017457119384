import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";

import { authorizedRequest } from "services/request";
import { IProperty } from "types/property.model";

interface InitialStateType {
  loading: boolean;
  propertyList: {
    items: IProperty[];
    totalPage: number;
  };
}

const initialState: InitialStateType = {
  loading: false,
  propertyList: {
    items: [],
    totalPage: 1
  }
};

export const getPropertyListByType = createAsyncThunk(
  "properties/getPropertyListByType",
  async (state: { type: string; page: number }, { rejectWithValue }) => {
    const { type, page } = state;
    const params = { limit: 10, page, type };

    try {
      const response = (await authorizedRequest.get(`/properties`, {
        params
      })) as any;
      return response;
    } catch (e: any) {
      return rejectWithValue(e.message);
    }
  }
);

const propertySlice = createSlice({
  name: "properties",
  initialState,
  reducers: {
    onDragEnd: (state, action) => {
      const items = [...state.propertyList.items];
      const [reorderedItem] = items.splice(action.payload.source, 1);
      items.splice(action.payload.destination, 0, reorderedItem);

      state.propertyList.items = items;
    }
  },
  extraReducers: {
    // property list
    [getPropertyListByType.pending.toString()]: (state) => {
      state.loading = true;
    },
    [getPropertyListByType.fulfilled.toString()]: (
      state,
      action: PayloadAction<{
        items: IProperty[];
        totalPage: number;
      }>
    ) => {
      state.loading = false;
      state.propertyList.items = [...action.payload.items].sort(
        (a, b) => a.serial - b.serial
      );
      state.propertyList.totalPage = action.payload.totalPage;
    },
    [getPropertyListByType.rejected.toString()]: (state) => {
      state.loading = false;
    }
  }
});

export const { onDragEnd } = propertySlice.actions;

export default propertySlice.reducer;
