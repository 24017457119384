import InputLabel from "@mui/material/InputLabel";
import FormHelperText from "@mui/material/FormHelperText";
import Box from "@mui/material/Box";
import { isEmpty } from "lodash";

import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "ckeditor5-classic-with-mathtype";
import { Button } from "../Button";
import { labelStyles, asteriskStyles } from "./MathEditor.styles";

interface MathEditorProps {
  required?: boolean;
  errorMessage?: string;
  label?: string;
  name?: string;
  removable?: boolean;
  value: string;
  onChange: (content: string, plainText: string) => void;
  onRemove?: () => void;
}

export default function MathEditor({
  required,
  label,
  errorMessage,
  removable,
  value,
  name,
  onRemove,
  onChange
}: MathEditorProps) {
  // const handleChange = (content: string) => {
  //   onChange(content, text || "");
  // };
  const handleChange = (event: any, editortext: any) => {
    const content = editortext.getData();
    onChange(content || "", event);
  };
  return (
    <div className="w-full">
      <Box
        sx={
          removable
            ? {
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                marginBottom: 1
              }
            : {}
        }
      >
        <InputLabel error={!isEmpty(errorMessage)} sx={labelStyles}>
          {label}{" "}
          {required && (
            <Box component="span" sx={asteriskStyles}>
              *
            </Box>
          )}
        </InputLabel>

        {removable && (
          <Button
            variant="contained"
            color="error"
            onClick={onRemove}
            sx={{ fontSize: "10px" }}
          >
            Remove
          </Button>
        )}
      </Box>

      <CKEditor
        editor={ClassicEditor}
        data={value}
        config={{
          toolbar: {
            shouldNotGroupWhenFull: true,
            items: ["MathType"]
          }
        }}
        onChange={handleChange}
      />
      {!isEmpty(errorMessage) && (
        <FormHelperText error sx={{ margin: "4px 14px 0px 14px" }}>
          {errorMessage}
        </FormHelperText>
      )}
    </div>
  );
}

MathEditor.defaultProps = {
  required: false,
  removable: false,
  errorMessage: "",
  label: "",
  onRemove: (index: number) => {},
  name: ""
};
