import { styled } from "@mui/material/styles";
import TextField from "@mui/material/TextField";
import { red } from "@mui/material/colors";

export const labelStyles = {
  fontSize: "0.875rem",
  fontWeight: 500,
  color: "#333333",
  marginBottom: 0.5
};

export const StyledTextField: any = styled(TextField)(() => ({
  color: "#333333",
  "& .Mui-disabled": {
    backgroundColor: "#e2e2e2"
  }
}));

export const asteriskStyles = { color: red[500] };
